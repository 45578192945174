export const projects = [
  {
    title: "Baseball Jobs Overseas",
    subtitle: "Wordpress",
    description:
      "Managed profile creation and editing with easy to use forms, Extended and customized ACF and rebuilt the profile creation process & user management, Integrated with the gmail API for email communication, Integrated with Mailchimp API for email collection",
    image: "./projects/BBJO-Screenshot.png",
    link: "https://baseballjobsoverseas.com",
  },
  {
    title: "Nature's Spirit Photography",
    subtitle: "React and Wordpress",
    description:
      `<ul class='list-disc text-left'>
        <li>Created a photography portfolio app with Gatsby as the front end and Wordpress as the back end</li>
        <li>Used the GatsbyImage package to optimize image loading speeds</li>
        <li>GraphQL queries Wordpress data and spits out an API used in photo album and page templates</li>
        <li>Deployed as a lightning fast static site with Netlify</li>
        <li>Webhook triggers Gatsby build on Netlify to re-build the static pages when Wordpress content is published</li>
        <li>SPA Design</li>
      </ul>`,
    image: "./projects/natures-spirit.gif",
    link: "https://natures-spirit.netlify.app",
  },
  {
    title: "College Baseball Database",
    subtitle: "React, Python & SQL",
    description:
      `<ul class='list-disc text-left'>
        <li>Scraped the web for data on every college baseball program in America</li>
        <li>Stored all data in a MySQL table, then created a searchable and filterable table using React & Datatables</li>
        <li>Modified the table expereince to be able to save data where users can compare two schools outside of the scope of the search</li>
      </ul>`,
    image: "./projects/cbd-screenshot.png",
    link: "https://collegebaseballdatabase.io",
  },
  {
    title: "The Pro's List",
    subtitle: "Wordpress",
    description:
      `<ul class='list-disc text-left'>
        <li>Membership site based on Advanced Custom Fields & Paid Memberships Pro</li>
        <li>Built a vanilla JS custom search and filter for athletic training businesses</li>
        <li>Built a distance calculator into the search using the Google Maps API and the Haversine function</li>
        <li>Created a front end profile editing dashboard for customers</li>
        <li>Used bootstrap 4 and material design bootstrap for css libraries</li>
        <li>Built custom business review system</li>
      </ul>`,
    image: "./projects/pros-list.gif",
    link: "https://theproslist.com",
  },
  {
    title: "AIS - Salzburg",
    subtitle: "Wordpress & Elementor",
    description:
      `<ul class='list-disc text-left'>
        <li>Site needed a mobile-first design</li>
        <li>Modified the search & filter process, created custom post types for each department</li>
        <li>Color and styles needed to be flexible</li>
        <li>Video and images needed to be front and center</li>
        <li>Certain pages needed to be editable without code using a page editor</li>
        <li>Created a partner page and portal for investors</li>
      </ul>`,
    image: "./projects/AIS-Screenshot.png",
    link: "https://ais-salzburg.at",
  },
  {
    title: "Bryan Ruby Music",
    subtitle: "Wordpress & Elementor",
    description:
      `<ul class='list-disc text-left'>
        <li>One page design</li>
        <li>Created a visual brand for Bryan Ruby</li>
        <li>Use of high quality images</li>
        <li>Integrated with Instagram, Spotify and Soundcloud</li>
        <li>Needed custom shortcodes to integrate with a page builder</li>
      </ul>`,
    image: "./projects/Ruby-Screenshot.png",
    link: "https://bryanrubymusic.com/",
  },
  {
    title: "Football Jobs Overseas",
    subtitle: "Wordpress",
    description:
      `<ul class='list-disc text-left'>
        <li>Built a membership site to promote football players to clubs abroad</li>
        <li>Developed a custom Wordpress theme focused around the google maps api & the wordpress api</li>
        <li>Built out a custom post type search & filter function</li>
      </ul>`,
    image: "./projects/FBJO-Screenshot.png",
    link: "https://fbjo.willnahmens.com/",
  },
  {
    title: "Hamiltons Gin & Tonic",
    subtitle: "Wordpress",
    description:
      `<ul class='list-disc text-left'>
        <li>Unique and original design for a South African gin company</li>
        <li>Displayed of high quality images</li>
        <li>Multi-step form for keg rentals</li>
      </ul>`,
    image: "./projects/Hamiltons-Screenshot.png",
    link: "https://www.hamiltonsgin.com/",
  },
];

export const testimonials = [
  {
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
    image: "https://randomuser.me/api/portraits/men/1.jpg",
    name: "Jesse Hicks",
    company: "Zoozle",
  },
  {
    quote:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
    image: "https://randomuser.me/api/portraits/men/94.jpg",
    name: "Ruben Alvarez",
    company: "Dooble.io",
  },
];

export const skills = [
  "JavaScript",
  "React",
  "Wordpress",
  "PHP",
  "MYSQL",
  "Linux",
];
